<template>
  <div class="center">
    <!--列表label-->
    <head-layout
      head-title="供应商管理"
      :head-btn-options="headBtnOptions"
      @head-remove="handleDelete"
      @head-add="headAdd"
    ></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :head-title="this.$t('cip.plat.sync.project.title.indexHeadTitle')"
      :table-options="option"
      :table-data="data"
      :table-loading="loading"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectionChange"
      @row-edit="rowEdit"
      @row-del="rowDel"
    >
    </grid-layout>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getList, remove } from "@/api/sync/supplier";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: { HeadLayout, GridLayout },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        // pageSize: 20,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        rowKey: "code",
        index: true,
        indexLabel: "序号",
        menuFixed: "right", //操作列冻结
        menuWidth: 120,
        // linklabel: "title",
        column: [
          {
            label: "公司名称",
            prop: "name",
            align: "left",
            overHidden: true,
            width: 300,
          },
          {
            label: "供应商编号",
            prop: "code",
            align: "left",
            overHidden: true,
            width: 120,
          },
          {
            label: "是否中建材集团",
            prop: "groupAround",
            type: "select",
            dataType: "number",
            align: "center",
            width: 140,
            dicData: [
              { label: "否", value: "0" },
              { label: "是", value: "1" },
            ],
            props: {
              label: "label",
              value: "value",
            },
            // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
            // props: {
            //   label: "dictValue",
            //   value: "dictKey"
            // },
          },
          {
            label: "所属集团",
            prop: "groupName",
            align: "center",
            overHidden: true,
            // width: 250,
          },
          {
            label: "境内外",
            prop: "projectArea",
            type: "select",
            dataType: "number",
            align: "center",
            width: 80,
            // dicData: [
            //   { label: "境内", value: "1" },
            //   { label: "境外", value: "2" },
            // ],
            // props: {
            //   label: "label",
            //   value: "value",
            // },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "供应商类型",
            prop: "kindName",
            // type: "select",
            align: "center",
            overHidden: true,
            width: 200,
            // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
            // props: {
            //   label: "dictValue",
            //   value: "dictKey"
            // },
          },
          {
            label: "分发类型",
            prop: "disType",
            type: "select",
            align: "center",
            overHidden: true,
            width: 100,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          // {
          //   label: "是否启用",
          //   prop: "status",
          //   type: "select",
          //   dataType: "number",
          //   align: "center",
          //   width: 120,
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          // },
        ],
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    // permissionList() {
    //   return {
    //     addBtn: this.vaildData(this.permission.personalSharing_add, false),
    //     delBtn: this.vaildData(this.permission.personalSharing_delete, false),
    //     editBtn: this.vaildData(this.permission.personalSharing_edit, false),
    //   };
    // },
    code() {
      let code = [];
      this.selectionList.forEach((ele) => {
        code.push(ele.code);
      });
      return code;
      // .join(",");
    },
    searchColumns() {
      return [
        {
          label: "公司名称",
          prop: "name",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "公司名称",
        },
        {
          label: "境内外",
          prop: "projectArea",
          type: "select",
          // dataType: "number",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "境内外",
          // dicData: [
          //   { label: "境内", value: "1" },
          //   { label: "境外", value: "2" },
          // ],
          // props: {
          //   label: "label",
          //   value: "value",
          // },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "是否中建材集团",
          prop: "groupAround",
          type: "select",
          span: 4,
          clearable: true,
          // dicData: [],
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "是否中建材集团",
          dicData: [
            { label: "否", value: "0" },
            { label: "是", value: "1" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey"
          //   },
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.status'),
        //   prop: "status",
        //   type: "select",
        //   dataType: "number",
        //   span: 4,
        //   dicData: [],
        //   clearable: false,
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.project.field.status'),
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        // },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      // if (this.permission.personalSharing_add) {
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        btnOptType: "add",
      });
      // }
      // if (this.permission.personalSharing_delete) {
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-remove",
        btnOptType: "remove",
      });
      // }
      return buttonBtn;
    },
    // 行按钮添加
    gridRowBtn() {
      let buttonBtn = [];
      // if (this.permission.personalSharing_edit) {
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.editBtn"),
        emit: "row-edit",
      });
      // }
      // if (this.permission.personalSharing_delete) {
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "row-del",
      });
      // }
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      // this.onLoad(this.page);

      this.onLoad(this.page, searchForm);
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      // this.onLoad(this.page);

      this.onLoad(this.page, searchForm);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },

    headAdd() {
      this.$router.push({
        path: `/system/sync/supplier/add`,
        query: {
          type: "add",
        },
      });
    },
    findchildren(arr, code) {
      for (let i = 0; arr.length > i; ++i) {
        let it = arr[i];
        if (code == it.code) {
          return it;
        }

        if (undefined != it.children) {
          let itt = this.findchildren(it.children, code);
          if (undefined != itt) {
            return itt;
          }
        }
      }
    },
    // 行编辑
    rowEdit(row) {
      let data = this.findchildren(this.data, row.code);
      this.$router.push({
        path: `/system/sync/supplier/edit`,
        query: {
          code: row.code,
          data: data,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/system/sync/supplier/view`,
        query: {
          code: row.code,
          type: "view",
        },
      });
    },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(row.code).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(this.code).then((res) => {
          console.log(this.code, "qqqqqqqqqqqqqqqq");
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(
        page.currentPage,
        page.pageSize,
        // params
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total =
          data == undefined || data.total == undefined ? 0 : data.total;
        this.$refs.gridLayOut.page.total = this.page.total;
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
      });
    },
  },
  // 初始化数据
  mounted() {
    this.onLoad(this.page, {});
  },

  created() {
    // this.onLoad(this.page, {});
  },
};
</script>
